// 归档计划表格
<template>

    <div class="comArchivingPlan">

        <div class="tableBox">
            <!-- 搜索条件 -->
            <div class="searchForm mb10">
                <el-form :model="searchForm"
                         ref="searchForm"
                         :rules="searchFormrules"
                         :inline="true">
                    <el-form-item label="实施任务"
                                  prop="name">
                        <el-input v-model="searchForm.name"
                                  placeholder="请输入实施任务"></el-input>
                    </el-form-item>
                    <el-form-item label="项目名称"
                                  prop="projectId">
                        <el-select v-model="searchForm.projectId"
                                   placeholder="请选择项目名称"
                                   filterable
                                   clearable>
                            <el-option v-for="item in itemSelectOpts"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目产品"
                                  prop="systemId">
                        <el-select v-model="searchForm.systemId"
                                   placeholder="请选择项目产品"
                                   filterable
                                   clearable>
                            <el-option v-for="item in systemListOpts"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary"
                                   @click="getList">搜索</el-button>
                        <el-button @click="resetSearchForm">清除</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <!-- 列表 -->
            <div class="tableBox">
                <el-table :data="tableData.data"
                          :height="tableHeight"
                          border
                          stripe>
                    <el-table-column type="index"
                                     label="序号"
                                     align="center"
                                     width="50">
                    </el-table-column>
                    <el-table-column v-for="col in tableData.columns"
                                     align="center"
                                     :prop="col.id"
                                     :key="col.id"
                                     :label="col.label"
                                     :min-width="col.width">
                    </el-table-column>
                    <el-table-column prop="id"
                                     label="操作"
                                     align="center"
                                     min-width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="details(scope.row)">查看详情</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" />
            </div>
        </div>
    </div>

</template>

<script>
import Pagination from "@/components/Pagination"; // 分页
import commonAPI from "@/api/commonAPI.js";
import API from "@/api/implementationManage.js";
import { Loading } from "element-ui";
export default {
    name: "comArchivingPlan",

    props: {
        // 激活的tab
        activeName: {
            type: String,
            default: "全部归档",
        },
        // 类名
        className: {
            type: String,
            default: "tab1",
        },
    },

    components: {
        Pagination,
    },

    data() {
        return {
            searchForm: {
                name: "", // 实施任务
                projectId: "", // 项目名称
                systemId: "", // 项目产品
                page: 1,
                size: 20,
            },
            pageTotal: 0,
            searchFormrules: {},
            itemSelectOpts: [{ label: "全部", value: "" }], // 软件信息下拉
            systemListOpts: [{ label: "全部", value: "" }], // 项目产品下拉
            tableData: {
                data: [],
                columns: [
                    { id: "name", label: "名称", width: "100" },
                    { id: "operatorName", label: "实施负责人", width: "100" },
                    { id: "taskStatus", label: "状态", width: "100" },
                    { id: "projectName", label: "项目名称", width: "100" },
                    { id: "systemInfoName", label: "项目产品", width: "100" },
                    { id: "startTime", label: "开始时间", width: "100" },
                    { id: "endTime", label: "预计完成时间", width: "100" },
                ],
            },
        };
    },

    created() {},

    /* 组件缓存触发 */
    activated() {
        // 使用软件 下拉
        this.getSystemList();
        // 项目名称 下拉列表
        this.getItemSelect();
        // 查询列表
        this.getList();
    },

    mounted() {
        // 使用软件 下拉
        this.getSystemList();
        // 项目名称 下拉列表
        this.getItemSelect();
        // 查询列表
        this.getList();
    },

    methods: {
        // 使用软件 下拉
        getSystemList() {
            commonAPI
                .getSystemList()
                .then((res) => {
                    let data = res.content;
                    let opt = data.map((val) => {
                        return {
                            label: val["label"],
                            value: val["code"],
                        };
                    });
                    this.systemListOpts = opt;
                })
                .catch(() => {
                    this.$message({
                        message: "获取使用软件失败，请稍后再试！",
                        type: "error",
                    });
                });
        },
        // 项目名称 下拉列表
        getItemSelect() {
            commonAPI
                .getItemSelect()
                .then((res) => {
                    let data = res.content;
                    let opt = data.map((val) => {
                        return {
                            label: val["label"],
                            value: val["code"],
                        };
                    });
                    this.itemSelectOpts = opt;
                })
                .catch(() => {
                    this.$message({
                        message: "获取项目名称失败，请稍后再试！",
                        type: "error",
                    });
                });
        },
        // 查询列表
        getList() {
            if (window.localStorage.getItem("tabId") === "null") {
                return;
            }

            let loading = Loading.service({
                target: document.querySelector(`.${this.className} .tableBox`),
            });
            let data = Object.assign({}, this.searchForm);
            let stageObj = {
                全部归档: ["ABOLISH", "FINISH"],
                完成: ["FINISH"],
                废止: ["ABOLISH"],
            };
            data.taskStatus = stageObj[this.activeName]; // 枚举【TaskStatus：IN_PROGRESS（进行中）ABOLISH（废止）FINISH（完成）】,查询归档任务列表，全部归档传参[ABOLISH,FINISH];查询完成列表传参[FINISH];查询废止列表传参[ABOLISH]
            API.getList(data)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    this.pageTotal = res.content.total;
                    // 更新count
                    this.$emit("update", this.activeName, {
                        totalCount: res.content.total,
                    });
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 清空表单
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    // 查询列表
                    this.getList();
                }
            });
        },

        // 分页改变
        paginationChange(e) {
            this.searchForm.size = e.size;
            this.searchForm.page = e.page;
            this.getList();
        },
        // 查看详情
        details(row) {
            window.localStorage.setItem("archivingPlanTab", this.activeName);
            this.$router.push({
                path: "/implementationManage/implementationManageDetails",
                query: {
                    status: "编辑",
                    id: row.id,
                },
            });
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 255 });
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
// .comArchivingPlan{

// }
</style>
