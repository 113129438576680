// 归档计划
<template>

    <div class="archivingPlan baseBg">

        <div class="listBox mainWhiteBg">
            <el-tabs v-model="activeName"
                     ref="searchTab"
                     class="searchTab">
                <template v-if="commonJs.isShowBtn(detailRole, 'Tab_全部归档')">
                    <el-tab-pane name="全部归档">
                        <div class="tabsLabel"
                             @click="commonJs.setTabId(detailRole,'Tab_全部归档')"
                             slot="label">
                            <el-badge :type="activeName == '全部归档' ? 'primary' : 'warning'"
                                      :value="counts.archiveNumber"
                                      class="item">
                                全部归档
                            </el-badge>
                        </div>
                        <!-- 归档计划表格 -->
                        <com-archiving-plan className="tab1"
                                            :key="activeName"
                                            @update="update"
                                            :activeName="activeName"></com-archiving-plan>
                    </el-tab-pane>
                </template>

                <template v-if="commonJs.isShowBtn(detailRole, 'Tab_完成')">
                    <el-tab-pane name="完成">
                        <div class="tabsLabel"
                             @click="commonJs.setTabId(detailRole,'Tab_完成')"
                             slot="label">
                            <el-badge :type="activeName == '完成' ? 'primary' : 'warning'"
                                      :value="counts.finishNumber"
                                      class="item">
                                完成
                            </el-badge>
                        </div>
                        <!-- 归档计划表格 -->
                        <com-archiving-plan className="tab2"
                                            :key="activeName"
                                            @update="update"
                                            :activeName="activeName"></com-archiving-plan>
                    </el-tab-pane>
                </template>

                <template v-if="commonJs.isShowBtn(detailRole, 'Tab_废止')">
                    <el-tab-pane name="废止">
                        <div class="tabsLabel"
                             @click="commonJs.setTabId(detailRole,'Tab_废止')"
                             slot="label">
                            <el-badge :type="activeName == '废止' ? 'primary' : 'warning'"
                                      :value="counts.abolishNumber"
                                      class="item">
                                废止
                            </el-badge>
                        </div>
                        <!-- 归档计划表格 -->
                        <com-archiving-plan className="tab3"
                                            :key="activeName"
                                            @update="update"
                                            :activeName="activeName"></com-archiving-plan>
                    </el-tab-pane>
                </template>

            </el-tabs>

        </div>
    </div>

</template>

<script>
import ComArchivingPlan from "./components/comArchivingPlan";
import { Loading } from "element-ui";
import API from "@/api/implementationManage.js";
export default {
    name: "archivingPlan",

    props: [],

    components: {
        ComArchivingPlan,
    },

    data() {
        return {
            activeName: "全部归档",
            counts: {}, // 数量
            detailRole: null, // 权限数据
        };
    },

    created() {
        this.commonJs.getFistTabId(this.detailRole, [
            "Tab_全部归档",
            "Tab_完成",
            "Tab_废止",
        ]);
    },

    /* 组件缓存触发 */
    // activated() {
    //     this.commonJs.getFistTabId(this.detailRole, [
    //         "Tab_全部归档",
    //         "Tab_完成",
    //         "Tab_废止",
    //     ]);
    //     // 实施管理 列表各状态计数
    //     this.archivingPlanCount();
    // },

    mounted() {
        // 实施管理 列表各状态计数
        this.archivingPlanCount();
    },

    methods: {
        // 更新计数
        update(ref, data) {
            let obj = {
                废止: "abolishNumber",
                完成: "finishNumber",
                全部归档: "archiveNumber",
            };
            this.counts[obj[ref]] = data.totalCount;
        },
        // 实施管理 列表各状态计数
        archivingPlanCount() {
            let loading = Loading.service({
                target: document.querySelector(".el-badge"),
            });
            API.archivingPlanCount()
                .then((res) => {
                    loading.close();
                    this.counts = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 新增项目
        addarchivingPlan() {
            this.$router.push({
                path: "/archivingPlan/archivingPlanDetails",
                query: {
                    status: "新增",
                    id: null,
                },
            });
        },
        //项目客户的选择
        projectCustClick(node) {
            this.searchForm.custUser = node.label;
            this.searchForm.custUserId = node.id;
        },
    },

    computed: {
        // 权限数据
        roleInfo() {
            return this.$store.state.role.roleInfo;
        },
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 225 });
        },
    },

    watch: {
        //角色按钮权限
        roleInfo: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.detailRole = this.commonJs.tabIsAlive(
                    val,
                    "归档计划_列表"
                );
                if (this.commonJs.isShowBtn(this.detailRole, "Tab_全部归档")) {
                    this.activeName = "全部归档";
                } else if (
                    this.commonJs.isShowBtn(this.detailRole, "Tab_完成")
                ) {
                    this.activeName = "完成";
                } else if (
                    this.commonJs.isShowBtn(this.detailRole, "Tab_废止")
                ) {
                    this.activeName = "废止";
                } else {
                    this.activeName = "";
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.archivingPlan ::v-deep .el-tabs__nav-scroll {
    overflow: visible;
}
.archivingPlan ::v-deep .el-tabs__nav-wrap {
    overflow: visible;
    height: 40px;
}
</style>
